import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Divider,
  Avatar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchViaPost } from "../helpers/fetchViaPost";
import { Save, RestartAlt, Calculate } from "@mui/icons-material";
import { fetchFromApi } from "../helpers/fetchFromApi";

export const FixtureDetails = ({ row, handleRecompile }) => {
  async function handleSave() {
    await fetchViaPost(
      "put-team-strengths",
      JSON.stringify([
        {
          ...localTeamStrength,
          total_strength: +localTeamStrength.total_strength,
        },
        {
          ...visitorTeamStrength,
          total_strength: +visitorTeamStrength.total_strength,
        },
      ])
    );
  }
  useEffect(() => {
    async function fetchStrengths() {
      const teamStrengths = await (
        await fetchViaPost(
          "get-team-strengths",
          JSON.stringify({
            season_id: row.season_id,
            team_ids: [row.localteam_id, row.visitorteam_id],
          })
        )
      ).json();

      setOriginalVisitorTeamStrength(teamStrengths[row.visitorteam_id]);
      setOriginalLocalTeamStrength(teamStrengths[row.localteam_id]);

      setLocalTeamStrength({
        ...teamStrengths[row.localteam_id],
      });
      setVisitorTeamStrength({
        ...teamStrengths[row.visitorteam_id],
      });
    }

    async function fetchStandings() {
      const response = await (
        await fetchFromApi(`fetch-data/standings/season/${row.season_id}`)
      ).json();

      const standings = response?.data[0]?.standings?.data?.filter((e) =>
        [row.localteam_id, row.visitorteam_id].includes(e.team_id)
      );
      const stats = standings.reduce((prev, e) => {
        const overallDiff =
          e?.overall?.goals_scored - e?.overall?.goals_against;
        const homeDiff = e?.home?.goals_scored - e?.home?.goals_against;
        const awayDiff = e?.away?.goals_scored - e?.away?.goals_against;
        return {
          ...prev,
          [e.team_id]: {
            league: {
              position: e?.position,
              games: e?.overall?.games_played,
              won: e?.overall?.won, //S
              draw: e?.overall?.draw, //U
              lost: e?.overall?.lost, //N
              goals: `${e?.overall?.goals_scored}:${e?.overall?.goals_against}`,
              diff: overallDiff > 0 ? `+${overallDiff}` : overallDiff,
              points: e?.overall?.points,
            },
            home: {
              position: e?.home?.position || "-",
              games: e?.home?.games_played,
              won: e?.home?.won, //S
              draw: e?.home?.draw, //U
              lost: e?.home?.lost, //N
              goals: `${e?.home?.goals_scored}:${e?.home?.goals_against}`,
              diff: homeDiff > 0 ? `+${homeDiff}` : homeDiff,
              points: e?.home?.points,
            },
            away: {
              position: e?.away?.position || "-",
              games: e?.away?.games_played,
              won: e?.away?.won, //S
              draw: e?.away?.draw, //U
              lost: e?.away?.lost, //N
              goals: `${e?.away?.goals_scored}:${e?.away?.goals_against}`,
              diff: awayDiff > 0 ? `+${awayDiff}` : awayDiff,
              points: e?.away?.points,
            },
          },
        };
      }, {});

      setStats(stats);
    }

    if (row.id) {
      fetchStrengths();
      fetchStandings();
    }
  }, [row.id]);
  const [localTeamStrength, setLocalTeamStrength] = useState();
  const [stats, setStats] = useState([]);
  const [visitorTeamStrength, setVisitorTeamStrength] = useState();
  const [originalLocalTeamStrength, setOriginalLocalTeamStrength] = useState();

  const [originalVisitorTeamStrength, setOriginalVisitorTeamStrength] =
    useState();
  if (!localTeamStrength || !stats[row.localteam_id]) {
    return (
      <Box sx={{ background: "white" }}>
        <Box
          sx={{
            p: "5em",
            display: "flex",
            justifyContent: "center",
            background: "rgb(255,181,99,0.2)",
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }
  const handleLocalTeamStrengthChange = ({
    target: { value: total_strength },
  }) =>
    setLocalTeamStrength((s) => {
      const newLocalTeamStrength = {
        ...s,
        total_strength: (+total_strength).toFixed(3),
        for_home:
          originalLocalTeamStrength.for_home *
          Math.abs(
            (+total_strength / originalLocalTeamStrength.total_strength - 1) /
              2 +
              1
          ),
        against_home:
          originalLocalTeamStrength.against_home /
          Math.abs(
            (+total_strength / originalLocalTeamStrength.total_strength - 1) /
              2 +
              1
          ),
        for_away:
          originalLocalTeamStrength.for_away *
          Math.abs(
            (+total_strength / originalLocalTeamStrength.total_strength - 1) /
              2 +
              1
          ),

        against_away:
          originalLocalTeamStrength.against_away /
          Math.abs(
            (+total_strength / originalLocalTeamStrength.total_strength - 1) /
              2 +
              1
          ),
      };
      return newLocalTeamStrength;
    });
  const handleVisitorTeamStrengthChange = ({
    target: { value: total_strength },
  }) =>
    setVisitorTeamStrength((s) => {
      const newVisitorTeamStrength = {
        ...s,
        total_strength: (+total_strength).toFixed(3),
        for_home:
          originalVisitorTeamStrength.for_home *
          Math.abs(
            (+total_strength / originalVisitorTeamStrength.total_strength - 1) /
              2 +
              1
          ),
        against_home:
          originalVisitorTeamStrength.against_home /
          Math.abs(
            (+total_strength / originalVisitorTeamStrength.total_strength - 1) /
              2 +
              1
          ),
        for_away:
          originalVisitorTeamStrength.for_away *
          Math.abs(
            (+total_strength / originalVisitorTeamStrength.total_strength - 1) /
              2 +
              1
          ),

        against_away:
          originalVisitorTeamStrength.against_away /
          Math.abs(
            (+total_strength / originalVisitorTeamStrength.total_strength - 1) /
              2 +
              1
          ),
      };
      return newVisitorTeamStrength;
    });

  return (
    <Box style={{ background: "white" }}>
      <Box
        sx={{ p: 2 }}
        style={{
          background: "rgb(255,181,99,0.2)",
          flexDirection: "column",
          display: "flex",
          gap: 10,
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ flex: 1 }}>
            <Typography
              component="h1"
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                justifyContent: "center",
              }}
            >
              <img
                src={row.localTeam.data.logo_path}
                style={{ height: "1.5em" }}
                alt=""
              />
              {row.localTeam.custom_name}
            </Typography>
          </Box>
          <Box style={{ flex: 1 }}>
            <Typography
              component="h1"
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                justifyContent: "center",
              }}
            >
              <img
                src={row.visitorTeam.data.logo_path}
                style={{ height: "1.5em" }}
                alt=""
              />
              {row.visitorTeam.custom_name}
            </Typography>
          </Box>
        </Box>
        <Box style={{ display: "flex", marginTop: "1em" }}>
          <Box
            style={{ flex: 1, display: "flex", justifyContent: "space-around" }}
          >
            {row.goalexp?.home?.toFixed(3) ? (
              <TextField
                onChange={() => {}}
                id="outlined-number"
                label="Home goal exp"
                type="number"
                value={row.goalexp?.home?.toFixed(3) ?? "-"}
                min="0"
                inputProps={{ step: "0.005" }}
              />
            ) : (
              <TextField
                onChange={() => {}}
                label="Home goal exp"
                disabled
                value="Compile the match first"
              />
            )}
            <TextField
              onChange={handleLocalTeamStrengthChange}
              id="outlined-number"
              label="Total strength"
              type="number"
              value={(+localTeamStrength.total_strength).toFixed(3)}
              min="0"
              inputProps={{ step: "0.005" }}
            />
          </Box>
          <Box
            style={{ flex: 1, display: "flex", justifyContent: "space-around" }}
          >
            {row.goalexp?.away?.toFixed(3) ? (
              <TextField
                onChange={() => {}}
                id="outlined-number"
                label="Away goal exp"
                type="number"
                value={row.goalexp?.away?.toFixed(3) ?? "-"}
                min="0"
                inputProps={{ step: "0.005" }}
              />
            ) : (
              <TextField
                onChange={() => {}}
                label="Away goal exp"
                disabled
                value="Compile the match first"
              />
            )}
            <TextField
              onChange={handleVisitorTeamStrengthChange}
              id="outlined-number"
              label="Total strength"
              type="number"
              value={(+visitorTeamStrength.total_strength).toFixed(3)}
              min="0"
              inputProps={{ step: "0.005" }}
            />
          </Box>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box style={{ flex: 1 }}>
            <Table>
              <TableHead>
                <th colSpan={2} align="left" style={{ width: "50%" }}>
                  League table
                </th>
                <th align="left">SP</th>
                <th align="left">S</th>
                <th align="left">U</th>
                <th align="left">N</th>
                <th align="left">Goals</th>
                <th align="left">Diff</th>
                <th align="left">Points</th>
              </TableHead>
              <TableBody>
                <TableRow>
                  <th>{stats[row.localteam_id].league.position}</th>
                  <td>{row.localTeam.custom_name}</td>
                  <td>{stats[row.localteam_id].league.games}</td>
                  <td>{stats[row.localteam_id].league.won}</td>
                  <td>{stats[row.localteam_id].league.draw}</td>
                  <td>{stats[row.localteam_id].league.lost}</td>
                  <td>{stats[row.localteam_id].league.goals}</td>
                  <td>{stats[row.localteam_id].league.diff}</td>
                  <td>{stats[row.localteam_id].league.points}</td>
                </TableRow>

                <TableRow>
                  <th>{stats[row.visitorteam_id].league.position}</th>
                  <td>{row.visitorTeam.custom_name}</td>

                  <td>{stats[row.visitorteam_id].league.games}</td>
                  <td>{stats[row.visitorteam_id].league.won}</td>
                  <td>{stats[row.visitorteam_id].league.draw}</td>
                  <td>{stats[row.visitorteam_id].league.lost}</td>
                  <td>{stats[row.visitorteam_id].league.goals}</td>
                  <td>{stats[row.visitorteam_id].league.diff}</td>
                  <td>{stats[row.visitorteam_id].league.points}</td>
                </TableRow>
                <TableRow>
                  <th colSpan={2} align="left" style={{ width: "50%" }}>
                    Home table
                  </th>
                  <th align="left">SP</th>
                  <th align="left">S</th>
                  <th align="left">U</th>
                  <th align="left">N</th>
                  <th align="left">Goals</th>
                  <th align="left">Diff</th>
                  <th align="left">Points</th>
                </TableRow>
                <TableRow>
                  <th>{stats[row.localteam_id].home.position}</th>
                  <td>{row.localTeam.custom_name}</td>

                  <td>{stats[row.localteam_id].home.games}</td>
                  <td>{stats[row.localteam_id].home.won}</td>
                  <td>{stats[row.localteam_id].home.draw}</td>
                  <td>{stats[row.localteam_id].home.lost}</td>
                  <td>{stats[row.localteam_id].home.goals}</td>
                  <td>{stats[row.localteam_id].home.diff}</td>
                  <td>{stats[row.localteam_id].home.points}</td>
                </TableRow>
                <TableRow>
                  <th colSpan={2} align="left" style={{ width: "50%" }}>
                    Away table
                  </th>
                  <th align="left">SP</th>
                  <th align="left">S</th>
                  <th align="left">U</th>
                  <th align="left">N</th>
                  <th align="left">Goals</th>
                  <th align="left">Diff</th>
                  <th align="left">Points</th>
                </TableRow>
                <TableRow>
                  <th>{stats[row.visitorteam_id].away.position}</th>
                  <td>{row.visitorTeam.custom_name}</td>

                  <td>{stats[row.visitorteam_id].away.games}</td>
                  <td>{stats[row.visitorteam_id].away.won}</td>
                  <td>{stats[row.visitorteam_id].away.draw}</td>
                  <td>{stats[row.visitorteam_id].away.lost}</td>
                  <td>{stats[row.visitorteam_id].away.goals}</td>
                  <td>{stats[row.visitorteam_id].away.diff}</td>
                  <td>{stats[row.visitorteam_id].away.points}</td>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box style={{ flex: 1 }}>
            {/* <Table>
              <TableHead>
                <TableRow>
                  <th colSpan={5} align="left">
                    Last games
                  </th>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.localTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    4:<strong>1</strong>
                  </td>
                  <td>
                    <strong>{row.localTeam.custom_name}</strong>
                  </td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "green" }}
                    >
                      S
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.localTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    4:<strong>1</strong>
                  </td>
                  <td>
                    <strong>{row.localTeam.custom_name}</strong>
                  </td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "green" }}
                    >
                      S
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.localTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td colspan={5}>
                    <Divider />
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.visitorTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    4:<strong>1</strong>
                  </td>
                  <td>
                    <strong>{row.visitorTeam.custom_name}</strong>
                  </td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "green" }}
                    >
                      S
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.visitorTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    4:<strong>1</strong>
                  </td>
                  <td>
                    <strong>{row.visitorTeam.custom_name}</strong>
                  </td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "green" }}
                    >
                      S
                    </Avatar>
                  </td>
                </TableRow>
                <TableRow>
                  <td>06/09/22</td>
                  <td>
                    <strong>{row.visitorTeam.custom_name}</strong>
                  </td>
                  <td>
                    <strong>1</strong>:4
                  </td>
                  <td>FC Shakhtar Donetsk</td>
                  <td>
                    <Avatar
                      variant="rounded"
                      sx={{ width: "1em", height: "1em", background: "red" }}
                    >
                      N
                    </Avatar>
                  </td>
                </TableRow>
              </TableBody>
            </Table> */}
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Box style={{ display: "flex", gap: 5 }}>
            <Button variant="contained" onClick={() => handleSave()}>
              <Save />
              <Box style={{ marginLeft: ".5em" }}>Save</Box>
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setLocalTeamStrength(originalLocalTeamStrength);
                setVisitorTeamStrength(originalVisitorTeamStrength);
              }}
            >
              <RestartAlt />
              <Box style={{ marginLeft: ".5em" }}>Reset</Box>
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleRecompile(
                  row.id,
                  row.season_id,
                  localTeamStrength,
                  visitorTeamStrength
                )
              }
            >
              <Calculate />
              <Box style={{ marginLeft: ".5em" }}>
                {typeof row.homewin === "undefined" ? "Recompile" : "Compile"}
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider style={{ height: "2em" }} />
    </Box>
  );
};
