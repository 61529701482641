import {
  Box,
  CircularProgress,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchFromApi } from "../helpers/fetchFromApi";
import { fetchViaPost } from "../helpers/fetchViaPost";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { STATI } from "../helpers/stati";
import { canEvaluate } from "../helpers/canEvaluate";
import { canCompile } from "../helpers/canCompile";
import enLocale from "date-fns/locale/en-GB";
import { FixturesFooter } from "./FixturesFooter";
import { FixturesToolbar } from "./FixturesToolbar";
import { fixtureColumns } from "../parts/fixtureColumns";
import { FixtureDetails } from "./FixtureDetails";

export function FixturesOverview({ leagues }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState();
  const [startDate, setStartDate] = useState(
    new Date(sessionStorage.getItem("startDate") ?? new Date())
  );

  const [mode, setMode] = useState("compile");
  const [forceCompile, setForceCompile] = useState(false);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );

  const handleModeChange = (event, newMode) => {
    if (newMode === null) return;
    setMode(newMode);
    setSelectionModel([]);
    setForceCompile(false);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const request = await fetchFromApi(
        `fetch-data/fixtures/between/${startDate.toISOString().split("T")[0]}/${
          startDate.toISOString().split("T")[0]
          // }?include=localTeam,visitorTeam,odds`
        }?include=localTeam,visitorTeam`
      );
      const response = await request.json();

      if (response.meta.pagination.links.next) {
        const page2 = await (
          await fetchFromApi(
            `fetch-data/fixtures/between/${
              startDate.toISOString().split("T")[0]
            }/${
              startDate.toISOString().split("T")[0]
            }?include=localTeam,visitorTeam&page=2`
          )
        ).json();
        response.data = [...response.data, ...page2.data];
      }

      const requestFixtures = response.data.map(
        ({ id, league_id, localteam_id, visitorteam_id }) => ({
          id,
          league_id,
          localteam_id,
          visitorteam_id,
        })
      );
      const details_response = await fetchViaPost(
        "get-details-for-fixtures",
        JSON.stringify({ fixtures: requestFixtures })
      );
      const details = await details_response.json();

      const fixtures = response.data.map((e) => ({
        ...e,
        ...details.find((d) => d.id === e.id),
      }));

      const rows = fixtures.map((fixture) => {
        const { name, country } =
          leagues.find((l) => l.id === fixture.league.id) ?? {};
        return {
          ...fixture,
          details: {
            date: new Date(fixture.time.starting_at.timestamp * 1000),
            id: fixture.id,
          },
          league: { name, country },
          localTeam: {
            ...fixture.localTeam,
            custom_name: fixture.teams[0].custom_name,
          },
          visitorTeam: {
            ...fixture.visitorTeam,
            custom_name: fixture.teams[1].custom_name,
          },
          ...fixture.probs,
        };
      });
      setRows(rows);
      setLoading(false);
    }

    if (leagues?.length) fetchData();
  }, [startDate, leagues]);

  async function handleCompileConfirm() {
    setLoading(true);
    try {
      const SLICE_LENGTH = 40;
      const numberOfSlices = Math.floor(selectionModel.length / SLICE_LENGTH);
      for (let i = 0; i <= numberOfSlices; i++) {
        const slice = selectionModel.slice(
          i * SLICE_LENGTH,
          (i + 1) * SLICE_LENGTH
        );
        const response = await (
          await fetchViaPost(
            "compile-fixtures-in-selection",
            JSON.stringify({ fixture_ids: slice })
          )
        ).json();
        if (response.success) {
          setRows((r) =>
            r.map((e) => {
              if (slice.includes(e.id)) {
                return {
                  ...e,
                  status: STATI["compiled"],
                  ...(response.result.find((o) => o.fixture_id === e.id)
                    ?.probs ?? {}),
                };
              }
              return e;
            })
          );
        }
      }
      setSelectionModel([]);
    } catch {}
    setLoading(false);
  }

  async function handleRecompile(
    fixture_id,
    season_id,
    team_strength_1,
    team_strength_2
  ) {
    const response = await fetchViaPost(
      "compile-odds-for-one-fixture-from-team-strengths",
      JSON.stringify({
        fixture_id,
        season_id,
        team_strength_1,
        team_strength_2,
      })
    );
    const body = await response.json();
    setRows(
      rows.map((e) => {
        if (e.id === fixture_id) {
          return {
            ...e,
            status: STATI["compiled"],
            goalexp: {
              home: body.homegoalexp,
              away: body.awaygoalexp,
            },
            ...body.probs,
          };
        }
        return e;
      })
    );
  }

  async function handleEvaluateConfirm() {
    setLoading(true);
    try {
      const SLICE_LENGTH = 10;
      const numberOfSlices = Math.floor(selectionModel.length / SLICE_LENGTH);
      for (let i = 0; i <= numberOfSlices; i++) {
        const slice = selectionModel.slice(
          i * SLICE_LENGTH,
          (i + 1) * SLICE_LENGTH
        );
        const response = await fetchViaPost(
          "update-parameters",
          JSON.stringify({ fixture_ids: slice })
        );
        const body = await response.json();
        if (body.success)
          setRows((r) =>
            r.map((e) => {
              if (selectionModel.includes(e.id)) {
                return { ...e, status: STATI["evaluation confirmed"] };
              }
              return e;
            })
          );
      }
      setSelectionModel([]);
    } catch {}
    setLoading(false);
  }

  function getDetailPanelContent(props) {
    return <FixtureDetails {...props} handleRecompile={handleRecompile} />;
  }

  if (loading)
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container maxWidth="xl" style={{ height: "100%" }}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          marginBottom: "1em",
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enLocale}
        >
          <DatePicker
            label="Date"
            value={startDate}
            onChange={(startDate) => {
              startDate.setHours(23);
              setStartDate(startDate);
              sessionStorage.setItem("startDate", startDate.toISOString());
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        Matches found: {rows.length}
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "75vh",
          "& .row--expanded": {
            bgcolor: (theme) => "rgb(255,181,99,0.2)",
          },
        }}
        component={Paper}
      >
        <div style={{ flexGrow: 1 }}>
          {!!rows?.length && (
            <DataGridPro
              onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
              getRowClassName={(params) =>
                `row--${
                  detailPanelExpandedRowIds.includes(params.row.id)
                    ? "expanded"
                    : "collapsed"
                }`
              }
              getDetailPanelHeight={() => "auto"}
              getDetailPanelContent={getDetailPanelContent}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              checkboxSelection
              disableSelectionOnClick
              isRowSelectable={({ row }) =>
                (mode === "compile" &&
                  canCompile(row.status) &&
                  ["NS", "POSTP", "TBA"].includes(row.time.status)) ||
                forceCompile ||
                (mode === "evaluate" &&
                  canEvaluate(row.status) &&
                  row.time.status === "FT")
              }
              components={{
                Toolbar: () => (
                  <FixturesToolbar
                    mode={mode}
                    handleModeChange={handleModeChange}
                    forceCompile={forceCompile}
                    setSelectionModel={setSelectionModel}
                    setForceCompile={setForceCompile}
                  />
                ),
                Footer: () => (
                  <FixturesFooter
                    selectionModel={selectionModel}
                    mode={mode}
                    handleCompileConfirm={handleCompileConfirm}
                    handleEvaluateConfirm={handleEvaluateConfirm}
                  />
                ),
              }}
              rows={rows}
              columns={fixtureColumns}
            />
          )}
        </div>
      </Box>
    </Container>
  );
}
