import {
  Button,
  Container,
  Typography,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { fetchViaPost } from "../helpers/fetchViaPost";
import { useState, useEffect } from "react";
import { AppBar } from "../styled-components/AppBar";
import { TeamStrengthEditor } from "./TeamStrengthEditor";
import { fixInconsistencies } from "../helpers/fixInconsistencies";
import { checkForInconsistencies } from "../helpers/checkForInconsistencies";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useConfirm } from "material-ui-confirm";
import { forwardRef, useRef } from "react";
import {extractFlag} from "./Help";

const CustomAlert = forwardRef(function CustomAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function TeamStrengthDialog({ league, setOpen }) {
  const promptToSaveChanges = useConfirm();
  const handleUnsavedChanges = async () => {
    try {
      await promptToSaveChanges({
        title: `Unsaved changes detected`,
        description: "Would you like to save them?",
      });
      await saveUnsavedChanges();
    } catch {
      discardUnsavedChanges();
    }
  };

  const apiRef = useRef(null);
  useState(false);
  function handleClose() {
    if (!hasChanges) setOpen(false);
    else handleUnsavedChanges();
  }

  async function saveUnsavedChanges() {
    await handleSave();
    setOpen(false);
    setHasChanges(false);
  }

  function discardUnsavedChanges() {
    setOpen(false);
    setHasChanges(false);
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  async function handleSave() {
    const strengths = rows.map((e) => {
      const { rank, total_goals, custom_name, ...row } = e;
      return row;
    });
    await fetchViaPost("put-team-strengths", JSON.stringify(strengths));
    setSnackbarOpen(true);
    setHasChanges(false);
  }
  const [teams, setTeams] = useState([]);
  const [rows, setRows] = useState([]);
  const [inconsistenciesFound, setInconsistenciesFound] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!league) return;
    async function fetchTeams() {
      setTeams([]);
      const teams = await (
        await fetchViaPost(
          "get-team-strengths-by-season-id",
          JSON.stringify({
            season_id: league.current_season_id,
          })
        )
      ).json();
      const values = Object.values(teams);
      setTeams(values);
      if (!checkForInconsistencies(values)) setInconsistenciesFound(true);
    }

    fetchTeams();
  }, [league]);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <CustomAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your changes have been saved.
        </CustomAlert>
      </Snackbar>

      <ConfirmationDialog
        open={inconsistenciesFound}
        onAbort={() => {
          setInconsistenciesFound(false);
        }}
        onConfirm={() => {
          setTeams(fixInconsistencies(teams));
          setInconsistenciesFound(false);
        }}
        title="Inconsistencies found"
        description="Would you like to fix them (recommended)?"
      />
      <Dialog
        fullScreen
        open={league}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                <img
                  style={{ width: "1em" }}
                  alt={league?.country?.data?.name}
                  title={league?.country?.data?.name}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    extractFlag(league?.country?.data?.extra)
                  )}`}
                />
                {league?.name}
              </div>
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSave}
              title="Saving your changes You can´t restore your former settings. You can still manually adjust again at any point."
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xxl">
          {!teams.length ? (
            <CircularProgress />
          ) : (
            <TeamStrengthEditor
              teams={teams}
              rows={rows}
              setRows={setRows}
              apiRef={apiRef}
              setHasChanges={setHasChanges}
            />
          )}
        </Container>
      </Dialog>
    </div>
  );
}
