import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import logo from "../images/transparent-logo.png";
import { FixturesOverview } from "./FixturesOverview";
import { fetchFromApi } from "../helpers/fetchFromApi";
import { useEffect, useState } from "react";
import { TeamStrengthDialog } from "./TeamStrengthDialog";
import { AppBar } from "../styled-components/AppBar";
import { LeagueStandingsDialog } from "./LeagueStandingsDialog";
import { UserDialog } from "./UserDialog";
import { extractFlag } from "../components/Help";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export function LeagueDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function fetchData() {
    const request = await fetchFromApi("fetch-data/leagues?include=country");
    const { data: leagues } = await request.json();

    setLeagues(leagues);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [leagues, setLeagues] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [league, setLeague] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function handleSelect(league) {
    setLeague(league);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorStandingsEl, setAnchorStandingsEl] = useState(null);
  const menuStandingsOpen = Boolean(anchorStandingsEl);
  const [standing, setStanding] = useState();
  const [user, setUser] = useState();

  const handleStandingsClick = (event) => {
    setAnchorStandingsEl(event.currentTarget);
  };
  function handleStandingsSelect(league) {
    setStanding(league);
  }
  const handleStandingsClose = () => {
    setAnchorStandingsEl(null);
  };

  async function fetchUser() {
    const user = await (await fetchFromApi("get-user")).json();
    setUser(user);
  }
  useEffect(() => {
    fetchUser();
  }, []);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  function handleSetUserDialogOpen(value) {
    setUserDialogOpen(value);
    if (!value) {
      fetchUser();
    }
  }
  return (
    <Box sx={{ display: "flex" }}>
      <TeamStrengthDialog league={league} setOpen={setLeague} />
      <LeagueStandingsDialog league={standing} setOpen={setStanding} />
      <UserDialog
        user={user}
        open={userDialogOpen}
        setOpen={handleSetUserDialogOpen}
      />
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} style={{ height: "3em" }} alt="betty" />
          <div>
            <Button
              id="basic-button"
              color="inherit"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              Team Settings
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {leagues?.map((league) => (
                <MenuItem onClick={() => handleSelect(league)}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <img
                      style={{ width: "1em" }}
                      alt={league.country.data.name}
                      title={league.country.data.name}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        extractFlag(league.country.data.extra)
                      )}`}
                    />
                    {league?.name}
                  </div>
                </MenuItem>
              ))}
            </Menu>
            <Button
              id="basic-button"
              color="inherit"
              aria-controls={menuStandingsOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuStandingsOpen ? "true" : undefined}
              onClick={handleStandingsClick}
              sx={{ ml: 2 }}
            >
              Standings
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorStandingsEl}
              open={menuStandingsOpen}
              onClose={handleStandingsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {leagues?.map((league) => (
                <MenuItem onClick={() => handleStandingsSelect(league)}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <img
                      style={{ width: "1em" }}
                      alt={league.country.data.name}
                      title={league.country.data.name}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        extractFlag(league.country.data.extra)
                      )}`}
                    />
                    {league?.name}
                  </div>
                </MenuItem>
              ))}
            </Menu>
            <Button href="help" target="_blank" color="inherit" sx={{ ml: 2 }}>
              Help
            </Button>
            <Button
              href="https://docs.betty.works"
              target="_blank"
              color="inherit"
              sx={{ ml: 2 }}
            >
              Docs
            </Button>
          </div>{" "}
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setUserDialogOpen(true)}
              edge="start"
              sx={{ ml: 2, ...(open && { display: "none" }) }}
            >
              <SettingsIcon />
            </IconButton>
            {user?.name}{" "}
            {window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
              ? `(${window.location.host})`
              : ""}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {leagues?.map(({ name, country }) => (
            <ListItem key={name}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <img
                        style={{ width: "1em" }}
                        alt={country.data.name}
                        title={country.data.name}
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          extractFlag(country.data.extra)
                        )}`}
                      />

                      {name}
                    </div>
                  }
                />
              </FormGroup>{" "}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <FixturesOverview leagues={leagues} />
      </Main>
    </Box>
  );
}
