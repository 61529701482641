import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export const LeagueStandingsToolbar = ({
                                  mode,
                                  handleModeChange,
                                }) => {
  return (
    <GridToolbarContainer>
      <ToggleButtonGroup
        color="primary"
        value={ mode }
        exclusive
        onChange={ handleModeChange }
      >
        <ToggleButton
          value="overall"
        >
          Total
        </ToggleButton>
        <ToggleButton
          value="home"
        >
          Home
        </ToggleButton>
        <ToggleButton
          value="away"
        >
          Away
        </ToggleButton>
      </ToggleButtonGroup>
    </GridToolbarContainer>
  );
};
