import { Typography } from "@mui/material";
import { Fragment } from "react";
import { HtmlTooltip } from "../styled-components/HtmlTooltip";

export function OddsTooltip({ children, highest, average }) {
  return (
    <div>
      <HtmlTooltip
        title={
          <Fragment>
            <Typography>highest: {highest}</Typography>
            <Typography>average: {average}</Typography>
          </Fragment>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
