import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useRef, useCallback } from "react";
import { teamStrengthColumns } from "../parts/teamStrengthColumns";
import { computeTeamStrengthMutation } from "../helpers/computeTeamStrengthMutation";
import { computeInterdependentValues } from "../helpers/computeInterdependentValues";
import { sortAndRankTeams } from "../helpers/sortAndRankTeams";

export function TeamStrengthEditor({
  teams,
  rows,
  setRows,
  apiRef,
  setHasChanges,
}) {
  useEffect(() => {
    setRows(sortAndRankTeams(teams));
  }, [teams, setRows]);
  const noButtonRef = useRef(null);
  const [promiseArguments, setPromiseArguments] = useState(null);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      return new Promise((resolve, reject) => {
        const mutation = computeTeamStrengthMutation(newRow, oldRow);
        if (mutation) {
          setHasChanges(true);
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow);
        }
      });
    },
    [setHasChanges]
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      const computedValues = computeInterdependentValues(newRow, oldRow);
      resolve(computedValues);
      setPromiseArguments(null);
      const updatedRow = { ...computedValues, isNew: false };
      setRows(
        rows.map((row) => (row.id === computedValues.id ? updatedRow : row))
      );
    } catch (error) {
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  useEffect(() => {
    if (promiseArguments && checked) {
      handleYes();
    }
  }, [promiseArguments, handleYes]);

  const handleCellEditCommit = () => {};

  const handleEntered = () => {};

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeTeamStrengthMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}. Keep in mind: the changes will not be stored to your data set before you select the "Save" button to the top right`}
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Don't show again during this dialog"
          />
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 300,
        width: "100%",
        "& .total-strength": {
          backgroundColor: theme.palette.primary.light,
          color: "white",
          fontWeight: "bold",
          fontSize: "1.5em",
        },
        "& .total-goals": {
          backgroundColor: theme.palette.secondary.light,
          color: "white",
          fontWeight: "600",
          fontSize: "1.5em",
        },
      }}
    >
      {renderConfirmDialog()}
      <DataGridPro
        onCellEditStop={handleCellEditCommit}
        rows={rows}
        columns={teamStrengthColumns}
        sx={{ height: "90vh" }}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        ref={apiRef}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
