import AwaitingCompilationIcon from "@mui/icons-material/HourglassEmpty";
import CompiledIcon from "@mui/icons-material/Addchart";
import CompilationConfirmedIcon from "@mui/icons-material/InsertChart";
import AwaitingEvaluationIcon from "@mui/icons-material/GppMaybe";
import EvaluationConfirmedIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import { OddsTooltip } from "../components/OddsTooltip";
import { STATI } from "../helpers/stati";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import { IconButton } from "@mui/material";
import ExpandIcon from "@mui/icons-material/Expand";
import {extractFlag} from "../components/Help";

function CustomDetailPanelToggle() {
  return (
    <IconButton aria-label="expand" title="expand to see fixture details">
      <ExpandIcon />
    </IconButton>
  );
}
export const fixtureColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    type: "string",
    field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
    renderCell: (params) => <CustomDetailPanelToggle {...params} />,
  },
  {
    headerName: "status",
    field: "status",
    flex: 0.5,
    renderCell: ({ value }) => {
      const map = {
        "awaiting compilation": AwaitingCompilationIcon,
        compiled: CompiledIcon,
        "compilation confirmed": CompilationConfirmedIcon,
        "awaiting evaluation": AwaitingEvaluationIcon,
        "evaluation confirmed": EvaluationConfirmedIcon,
      };
      const map2 = Object.keys(map).reduce(
        (prev, cur) => ({
          ...prev,
          [STATI[cur]]: { icon: map[cur], title: cur },
        }),
        {}
      );
      const { icon: Icon, title } = map2[value] ?? {
        icon: HelpIcon,
        title: "no status",
      };
      return <Icon titleAccess={title} />;
    },
  },
  {
    headerName: "kickoff time",
    field: "details",
    flex: 1,
    renderCell: ({ value }) => (
      <div title={value.id}>
        <div>
          {value.date.toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "numeric",
          })}{" "}
          {value.date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    ),
  },
  {
    headerName: "competition",
    field: "league",
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
          <img
            style={{ width: "1em" }}
            alt={value.country.data.name}
            title={value.country.data.name}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              extractFlag(value.country.data.extra)
            )}`}
          />
          {value.name}
        </div>
      );
    },
  },
  {
    headerName: "home team",
    field: "localTeam",
    flex: 1.5,
    renderCell: ({ value }) => (
      <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
        <img src={value.data.logo_path} style={{ height: "1.5em" }} alt="" />
        {value.custom_name}
      </div>
    ),
  },
  {
    headerName: "away team",
    field: "visitorTeam",
    flex: 1.5,
    renderCell: ({ value }) => (
      <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
        <img src={value.data.logo_path} style={{ height: "1.5em" }} alt="" />
        {value.custom_name}
      </div>
    ),
  },
  {
    headerName: "result",
    field: "scores",
    flex: 1,
    renderCell: ({ value }) => (
      <span>{`${value.ft_score ?? "-"} (${value.ht_score ?? "-"})`}</span>
    ),
  },
  {
    headerName: "true home",
    field: "homewin",
    flex: 1,
    renderCell: ({ value, row }) => (
      <OddsTooltip
      // highest={Math.max(
      //   ...row.odds.data
      //     .find((e) => e.name === "3Way Result")
      //     ?.bookmaker.data.map(
      //       (e) => e.odds.data.find((e) => e.label === "1")?.value
      //     )
      // )}
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
  {
    headerName: "true draw",
    field: "draw",
    flex: 1,
    renderCell: ({ value, row }) => (
      <OddsTooltip
      // highest={Math.max(
      //   ...row.odds.data
      //     .find((e) => e.name === "3Way Result")
      //     ?.bookmaker.data.map(
      //       (e) => e.odds.data.find((e) => e.label === "X")?.value
      //     )
      // )}
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
  {
    headerName: "true away",
    field: "awaywin",
    flex: 1,
    renderCell: ({ value, row }) => (
      <OddsTooltip
      // highest={Math.max(
      //   ...row.odds.data
      //     .find((e) => e.name === "3Way Result")
      //     ?.bookmaker.data.map(
      //       (e) => e.odds.data.find((e) => e.label === "2")?.value
      //     )
      // )}
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
];
