import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LeagueDrawer } from "./LeagueDrawer";
import { Auth } from "./Auth";
import { ConfirmProvider } from "material-ui-confirm";
import { Help } from "./Help";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(123,60,89)",
      light: "rgba(123,60,89,0.75)",
    },
    secondary: {
      main: "rgb(213,57,57)",
      light: "rgba(213,57,57,0.75)",
    },
    background: {
      default: "rgb(255,181,99)",
      pale: "rgb(255,181,99,0.2)",
    },
  },
});

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
)
  window.document.title = window.location.host;

export function App() {
  const path = window.location.pathname.slice(1);
  if (!path.length || path === "sign-in" || path === "sign-up")
    return <Auth method={path} />;
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        {path === "help" ? <Help /> : <LeagueDrawer />}
      </ConfirmProvider>
    </ThemeProvider>
  );
}
